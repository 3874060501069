.video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 0.2);
	backdrop-filter: blur(5px);
	z-index: 10;
	pointer-events: none;
	user-select: none;
	opacity: 0;
	transition: opacity ease 1s;
	border-radius: 0.65rem;
}

.video.show {
	opacity: 1;
}

.video.hide {
	opacity: 0;
}

.toggleMuted {
	position: absolute;
	top: 13%;
	right: 0;
	background-color: rgb(0 0 0 / 0.5);
	padding: 0.9rem 1.2rem;
	border-radius: 1rem 0 0 1rem;
	z-index: 15;
	cursor: pointer;
	user-select: none;
	opacity: 0.4;
	transition: opacity ease 0.3s;
}

.toggleMuted img {
	width: 2rem;
	height: 2rem;
}

.toggleMuted:hover {
	opacity: 1;
}

@media (max-width: 991px) {
	.toggleMuted {
		padding: 0.7rem 1rem;
	}

	.toggleMuted img {
		width: 1.8rem;
		height: 1.8rem;
	}
}