.wrapper {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 0.2);
	backdrop-filter: blur(5px);
    z-index: 9;
}

.iframe {
    position: fixed;
	top: 50%;
	left: 50%;
	width: 80%;
	height: 80%;
	transform: translate(-50%, -50%);
	z-index: 10;
	opacity: 0;
	transition: opacity ease 1s;
    border: none;
    border-radius: 0.65rem;
}

.iframe.show {
    opacity: 1;
}