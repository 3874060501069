.form {
	width: 70%;
	--input-height: 3.2rem;
	--input-font-size: 1rem;
	--input-border-radius: 0.8rem;
	--input-padding-x: 1.2rem;
	--input-padding-y: 0.8rem;
	--select-padding-x: 0.7rem;
}

.inputGroup {
	margin-bottom: 1.5rem;
}

.input {
	font-family: 'Montserrat', sans-serif;
	width: 100%;
	height: var(--input-height);
	border-radius: var(--input-border-radius);
	outline: none;
	padding: var(--input-padding-y) var(--input-padding-x);
	font-size: var(--input-font-size);
	border-style: solid;
	border-width: 1px;
	border-color: transparent;
	background: rgb(255 255 255 / 0.4);
	color: #fff;
}

.input:focus {
	border-color: #fff;
	box-shadow: 0 0 0 1px #fff;
}

.input::placeholder {
	color: #fff;
}

.submit {
	width: 100%;
	text-align: center;
	cursor: pointer;
	margin-top: 1rem;
	background: none;
	border: none;
	outline: none;
}

.submit img {
	width: 100%;
	max-width: 60px;
}

@media (max-width: 1199px) {
	.form {
		width: 90%;
	}
}

@media (max-width: 991px) {
	.form {
		width: 100%;
		--input-height: 2.5rem;
		--input-font-size: 0.8rem;
		--input-border-radius: 0.7rem;
		--input-padding-x: 1rem;
		--input-padding-y: 0.6rem;
		--select-padding-x: 0.5rem;
	}
	.submit {
		font-size: 1.4rem;
	}
}

@media (max-width: 767px) {
	.form {
		--input-height: 2.5rem;
		--input-border-radius: 0.6rem;
		--input-padding-x: 0.8rem;
		--input-padding-y: 0.5rem;
		--select-padding-x: 0.3rem;
	}
	.inputGroup {
		padding: 0 0 0 1rem;
	}
	.submit {
		font-size: 1.2rem;
		padding: 0 3rem;
	}
}