.download {
	position: fixed;
	left: 0;
	bottom: 2%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	z-index: 20;
}

.downloadBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(0 0 0 / 0.4);
	backdrop-filter: blur(5px);
	padding: 1.6rem;
	border-radius: 50%;
	opacity: 0.4;
	transition: opacity ease 0.3s;
}

.downloadBtn:hover {
	opacity: 1;
}

.downloadBtn img {
	width: 2rem;
	height: 2rem;
	opacity: 0.8;
}