.background {
    position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
    object-position: right;
	z-index: -1;
	pointer-events: none;
	user-select: none;
}

@media screen and (max-width: 768px) {
    .background {
        object-position: left;
    }
}